<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="$t('view.station.title')">
          <div class="row">
            <div class="col-md-3">
              <button type="button" v-on:click="backPage" class="btn btn-primary btn-sm">Back</button>
            </div>
            <div class="col-md-3 offset-md-6">
              <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
            </div>
          </div>
        </vuestic-widget>
      </div>
      <div class="col-md-12">
        <vuestic-widget :headerText="header">
          <div class="row">
            <div class="col-md-6">
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}"
              >
                <div class="input-group">
                  <input id="name" name="name" v-model="name" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label class="control-label" for="name">{{'view.station.fields.name' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('name')"
                    class="help text-danger"
                  >{{ errors.first('name') }}</small>
                </div>
              </div>

              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('location'), 'valid': isSuccessfulLocationValid}"
              >
                <div class="input-group">
                  <input id="location" name="location" v-model="location" >
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="location"
                  >{{'view.station.fields.location' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('location')"
                    class="help text-danger"
                  >{{ errors.first('location') }}</small>
                </div>
              </div>

               <div class="form-group" >
                                <div class="input-group">
                                    <input type='number' id="order" name="order" v-model="order" />
                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                    <label class="control-label" for="order">{{'view.station.fields.order' | translate}} </label><i class="bar"></i>

                                </div>
                            </div>


            </div>
            <div class="col-md-6">
                 <multiselect
                v-model="typestation"
                deselect-label="Selected Element"
                track-by="name"
                group-values="stations"
                group-label="name"
                :group-select="false"
                label="name"
                placeholder="Tipo de Estación"
                :options="typestationlist"
                :searchable="false"
                :allow-empty="false"
                @input="loadTypestationsActive"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Tipo de Estación:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
              <br>
              <multiselect
                v-model="ubication"
                deselect-label="Selected Element"
                track-by="name"
                label="name"
                placeholder="Ubicación"
                :options="ubicationlist"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Ubicación de Estación:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
                <br>
              <multiselect
                v-model="day"
                deselect-label="Selected Element"
                track-by="name"
                label="name"
                placeholder="Día"
                :options="daylist"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Día en el Evento:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
              <!--  <vuestic-simple-select :label="'view.station.fields.categorystation' | translate" v-model="categorystation" v-on:change="onChange" option-key="name" v-bind:options="categorystationoptions">
                        </vuestic-simple-select>

                          <vuestic-simple-select :label="'view.station.fields.typestation' | translate" v-model="typestation" option-key="name" v-bind:options="typestationoptions">
              </vuestic-simple-select>-->

              <br>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <label
                class="control-label"
                for="location"
              >{{'view.station.fields.status' | translate}}</label>

              <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">
                <div class="input-group">
                  <vuestic-switch v-model="active">
                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                  </vuestic-switch>
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>

                  <small
                    v-show="errors.has('active')"
                    class="help text-danger"
                  >{{ errors.first('active') }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="statusEvent != 3">
            <button
              type="button"
              v-on:click="saveStaff"
              class="btn btn-primary btn-sm"
            >Add New Station</button>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TableStation from '../tables/TableStation/TableStation.vue'
import Multiselect from 'vue-multiselect'
import { SpringSpinner } from 'epic-spinners'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'new-station',
  components: {
    SpringSpinner,
    TableStation,
    Multiselect
  },
  mounted () {
    // checkbox and radios

    this.loadTypestationsActive()
      .then(data => {
        // this.typestationlist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })

        this.loadUbicationEventByEvent(this.idevent)
      .then(data => {
        this.ubicationlist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })

       this.loadDayEventByEvent(this.idevent)
      .then(data => {
        this.daylist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })
  },
  computed: {
    ...mapState({
      typestationlist: state => state.typestation.typestations,
      categorystationoptions: state => state.typestation.categorystations,
      idevent: state => state.event.event.id,
       statusEvent: state => state.event.event.status,
    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulLocationValid () {
      let isValid = false
      if (this.formFields.location) {
        isValid =
          this.formFields.location.validated && this.formFields.location.valid
      }
      return isValid
    }
  },
  data () {
    return {
      header: 'Nueva Estación',
      typestation: [],
      categorystation: [],
        ubicationlist:[],
      daylist:[],
      //  typestationlist: [],
      ubication:'',
      day:'',
      // typestationlist: [],
      name: '',
      order:'',
      location: '',
      active: 1,
      eventoptions: null
    }
  },
  watch: {
    categorystation: {
      handler (categorystation) {
        if (categorystation != '') {

        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      'loadTypestationsActive',
      'loadCategorystations',
      'storeStation',
      'addToastMessage',
       'loadUbicationEventByEvent',
      'loadDayEventByEvent'
    ]),
    backPage: function () {
      this.$router.go(-1)
    },
    saveStaff () {
      this.$validator.validate().then(result => {
        if (result) {
          this.storeStation({
            name: this.name,
            location: this.location,
            id_event: this.idevent,
            active: this.active,
            id_type_station: this.typestation.id,
             id_ubication:this.ubication.id,
            id_day_event:this.day.id,
            order:this.order
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.replace('/station'), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.addToastMessage({
                text: data.message,
                type: 'warning'
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
